/* @media only screen and (min-width : 2080px) {
    .container {
        max-width: 1800px;
        max-width: calc(100% - 60rem);
    }
} */

@media screen and (max-width : 900px) {

    .nav-links {
        position: absolute;
        background-color: var(--accent-color);
        width: 100%;
        height: 0;
        overflow: hidden;
        top: 4.5rem;
        left: 0;
        right: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        opacity: 0;
        /* box-shadow: 0 10px 7px -10px var(--shadow-color); */
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
        box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
        transition: all 0.5s cubic-bezier(0.23, -0.18, 0.38, 0.68);
    }

    .nav-links.open {
        height: 300px;
        opacity: 1;
    }

    .nav-links ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    #checkbox {
        right: 50px;
    }

    .ham-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
    }
}

@media screen and (max-width : 680px) {
    .card {
        max-width: 350px;
    }

    .card-header img {
        max-width: 100%;
        min-height: auto;
        max-height: 200px;
    }
}

@media screen and (max-width : 540px) {

    .logo img {
        max-width: 100px;
    }

    .search-bar {
        position: absolute;
        top: 4.5rem;
        left: 0;
        background-color: var(--accent-color);
        width: 100%;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 2px solid #4242426e;
        /* box-shadow: 0 10px 7px -10px var(--shadow-color); */
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        /* box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
    }

    .news-input {
        height: 35px;
    }

    .search-bar .search-btn {
        font-size: 0;
        height: 35px;
    }

    .search-btn i {
        display: block;
        font-size: 1rem;
    }

    #bars {
        transition: all 0.3s;
    }

    main {
        margin-top: 100px;
    }

    .card {
        min-width: auto;
        min-height: 450px;
    }

    .card-header img {
        height: auto;
    }

    #news-title {
        font-size: 1rem;
    }

    #news-source {
        font-size: 0.6rem;
    }

    #news-desc {
        font-size: 0.9rem;
    }
}